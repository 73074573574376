import { Subject } from 'rxjs';
import authAPI from '../../axios-setup/auth-service';

const postSubject = new Subject();

export const postService = {
  addPost: post => postSubject.next({
    type: 'add',
    data: post
  }),
  updatePost: post => postSubject.next({
    type: 'update',
    data: post
  }),
  deletePost: post_id => postSubject.next({
    type: 'delete',
    data: post_id
  }),
  getPost: () => postSubject.asObservable()
};

export const commentService = {
  addComment: comment => postSubject.next({
    type: 'add',
    data: comment
  }),
  updateComment: comment => postSubject.next({
    type: 'update',
    data: comment
  }),
  deleteComment: comment_id => postSubject.next({
    type: 'delete',
    data: comment_id
  }),
  getComments: () => postSubject.asObservable()
};

const successResponse = (data = undefined) => {
  return { 'success': true, 'data': data };
}

const errorResponse = () => {
  return { 'success': false };
}

const apiCall = async (data) => {
  return await authAPI.post("/api/v1/app/mapper", data);
}

export const PostAPI = {
  getTopics: async () => {
    try {
      const res = await apiCall({
        method: "get",
        url: "posts/topics",
      })
      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data.topics);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  getTopic: async (tag) => {
    try {
      const res = await apiCall({
        method: "get",
        url: "posts/topic?slug=" + tag,
      })
      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data.topic);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  subscirbeTopic: async (payload) => {
    try {
      const res = await apiCall({
        method: "post",
        url: "posts/subscribe-topic",
        data: payload
      })
      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data.count);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  getPosts: async (perPage = 10, lastPostId = null, tag = null) => {
    try {
      let url = `posts/?limit=${perPage}`;
      if (lastPostId) {
        url += `&last_post_id=${lastPostId}`
      }
      if (tag) {
        url += `&tag=${tag}`
      }
      const res = await apiCall({
        method: "get",
        url: url,
      });

      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data.posts);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  getPost: async (id) => {
    try {
      const res = await apiCall({
        method: "get",
        url: `posts/${id}`,
      })

      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data.post);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  createPost: async (payload) => {
    try {
      const res = await apiCall({
        method: "post",
        url: "posts/store",
        data: payload,
      })

      if (res.data.success || res.data.status === 'success') {
        return successResponse(res.data.data.post);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error)
      return errorResponse();
    }
  },
  getTags: async () => {
    try {
      const res = await apiCall({
        method: "get",
        url: "posts/tags",
      })

      if (res.data.success || res.data.status === 'success') {
        return successResponse(res.data.data.tags);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error)
      return errorResponse();
    }
  },
  likeContent: async (payload) => {
    try {
      let response = await apiCall({
        method: "post",
        url: "posts/like-content",
        data: payload
      })

      if (response.data.success || response.data.status === 'success') {
        return successResponse(response.data.data.likes_count);
      } else {
        return errorResponse();
      }
    } catch (error) {
      return errorResponse();
    }
  },
  savePost: async (postId, payload) => {
    try {
      let response = await apiCall({
        method: "post",
        url: `posts/${postId}/bookmark`,
        data: payload
      })

      if (response.data.success || response.data.status === 'success') {
        return successResponse();
      } else {
        return errorResponse();
      }
    } catch (error) {
      return errorResponse();
    }
  },
  bookmarkedCount: async () => {
    try {
      let response = await apiCall({
        method: "get",
        url: "posts/bookmarked-count",
      })

      if (response.data.success || response.data.status === 'success') {
        return successResponse(response.data.data.count);
      } else {
        return errorResponse();
      }
    } catch (error) {
      return errorResponse();
    }
  },
  clearBookmarks: async () => {
    try {
      let response = await apiCall({
        method: "delete",
        url: "posts/bookmarks",
      })

      if (response.data.success || response.data.status === 'success') {
        return successResponse();
      } else {
        return errorResponse();
      }
    } catch (error) {
      return errorResponse();
    }
  },
  pinPost: async (postId, payload) => {
    try {
      let response = await apiCall({
        method: "post",
        url: `posts/${postId}/pin`,
        data: payload
      })

      if (response.data.success || response.data.status === 'success') {
        return successResponse();
      } else {
        return errorResponse();
      }
    } catch (error) {
      return errorResponse();
    }
  },
  addVote: async (postId, payload) => {
    try {
      let response = await apiCall({
        method: "post",
        url: `posts/${postId}/add-vote`,
        data: payload
      })

      if (response.data.success || response.data.status === 'success') {
        return successResponse(response.data.data);
      } else {
        return errorResponse();
      }
    } catch (error) {
      return errorResponse();
    }
  },
  addComment: async (postId, payload) => {
    try {
      let response = await apiCall({
        method: "post",
        url: `posts/${postId}/comment`,
        data: payload
      })

      if (response.data.success || response.data.status === 'success') {
        return successResponse(response.data.data.comment);
      } else {
        return errorResponse();
      }
    } catch (error) {
      return errorResponse();
    }
  },
  getComments: async (postId, payload = null) => {
    try {
      let url = `posts/${postId}/comments?`;
      if (payload) {
        for (const [key, value] of Object.entries(payload)) {
          url += `${key}=${value}&`;
        }
      }
      let response = await apiCall({
        method: "get",
        url: url,
      })

      if (response.data.success || response.data.status === 'success') {
        return successResponse(response.data.data.comments);
      } else {
        return errorResponse();
      }
    } catch (error) {
      return errorResponse();
    }
  },
  reportContent: async (payload) => {
    try {
      let response = await apiCall({
        method: "post",
        url: "posts/report-content",
        data: payload
      })

      return successResponse();
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  deleteContent: async (payload) => {
    try {
      let response = await apiCall({
        method: "delete",
        url: "posts/delete-content",
        data: payload
      })

      return successResponse();
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  deleteComment: async (payload) => {
    try {
      let response = await apiCall({
        method: "delete",
        url: `posts/comments`,
        data: payload
      })

      if (response.data.success || response.data.status === 'success') {
        return successResponse();
      } else {
        return errorResponse();
      }
    } catch (error) {
      return errorResponse();
    }
  }
}

export const ProfileAPI = {
  getInfo: async () => {
    try {
      const res = await apiCall({
        method: "get",
        url: "profile/info",
      })

      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  getProfile: async () => {
    try {
      const res = await apiCall({
        method: "get",
        url: "profile",
      })

      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  update: async (formData) => {
    try {
      const res = await authAPI.post("/api/v1/app/post-mapper", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });

      if (res.data.status === 'success' || res.data.success) {
        return successResponse();
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  updateProfile: async (payload) => {
    try {
      const res = await apiCall({
        method: "patch",
        url: "profile/update",
        data: payload
      })

      if (res.data.status === 'success' || res.data.success) {
        return successResponse();
      } else {
        return errorResponse();
      }
    } catch (error) {
      return errorResponse();
    }
  }
}

const programSubject = new Subject();

export const programService = {
  updateProgress: progress => programSubject.next({
    type: 'progress',
    data: progress
  }),
  program: () => programSubject
}


export const ProgramApi = {
  getPrograms: async (type) => {
    try {
      const res = await apiCall({
        method: "get",
        url: "programs?type=" + type,
      })

      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data.programs);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  getFeaturedPrograms: async () => {
    try {
      const res = await apiCall({
        method: "get",
        url: "programs?featured=Y",
      })

      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data.programs);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  info: async (slug) => {
    try {
      const res = await apiCall({
        method: "get",
        url: "programs/info?slug=" + slug,
      })

      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data.program);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  modules: async (slug) => {
    try {
      const res = await apiCall({
        method: "get",
        url: "programs/curriculum?slug=" + slug,
      })

      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  lessons: async (slug) => {
    try {
      const res = await apiCall({
        method: "get",
        url: "programs/lessons?slug=" + slug,
      })

      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data.modules);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  lessonData: async (slug, lesson_id) => {
    try {
      const res = await apiCall({
        method: "get",
        url: "programs/lesson-data?slug=" + slug + "&lesson_id=" + lesson_id,
      })

      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data.lesson);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  checkSubscribed: async (slug) => {
    try {
      const res = await apiCall({
        method: "get",
        url: "programs/check-subscribed?slug=" + slug,
      })

      if (res.data.status === 'success' || res.data.success) {
        return successResponse(res.data.data);
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  submitClassProgress: async (payload) => {
    try {
      const res = await apiCall({
        method: "post",
        url: "programs/submit-class-progress",
        data: payload
      })

      if (res.data.status === 'success' || res.data.success) {
        return successResponse();
      } else {
        return errorResponse();
      }
    } catch (error) {
      console.log(error);
      return errorResponse();
    }
  },
  addClassProgress: async (class_id, duration) => {
    let progresses = localStorage.getItem('classProgress') ? JSON.parse(localStorage.getItem('classProgress')) : [];

    let check = progresses.find(item => item.class_id === class_id);

    if (!check) {
      progresses.push({
        class_id: class_id,
        duration: duration
      })
    } else {
      for (let i = 0; i < progresses.length; i++) {
        if (progresses[i].class_id === class_id) {
          progresses[i].duration = duration;
        }
      }
    }
    localStorage.setItem('classProgress', JSON.stringify(progresses));
  },
  pushProgresses: async () => {
    let progresses = localStorage.getItem('classProgress') ? JSON.parse(localStorage.getItem('classProgress')) : [];
    for (let i = 0; i < progresses.length; i++) {
      ProgramApi.submitClassProgress({
        class_id: progresses[i].class_id,
        duration: progresses[i].duration
      });
    }
    localStorage.removeItem('classProgress');
  }
} 